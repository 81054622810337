const IS_TESTING = false;
const ENV = {
  BASE_URL: "https://api.vehiclecare.in",
  WAVE_URL: "https://wave.vehiclecare.app/api/warranty/store",
  //BASE_URL: "http://127.0.0.1/prod_apirepo/",
  ACCESS_KEY: "vprod8cebfbf744062c00aa85dabcc68c08d0c",
  DEVICE: "Web App",
  EXTRA_API: "extraApi",
  PRE_LOGIN: "preLogin",
  LOGIN: "login",
  POST_LOGIN: "postLogin",
  VC_PARTNER_LOGIN: "vc_partner_login",
  VC_PARTNER_POST_LOGIN: "vc_partner_post_Login",
  INSURANCE_PARTNER_ACCESS_KEY: "insprtvcprod8cebfb7s8dbf78sdf678dsfc08d0c",
  PARTNER_NAME: "vehiclecare",
  PARTNER_ID: 5,
  PAYMENT_MODE: "production",
  IS_TESTING,
};
if (IS_TESTING) {
  ENV.BASE_URL = "https://devapi.vehiclecare.in";
  ENV.INSURANCE_PARTNER_ACCESS_KEY = "insprtvc8cebfb7s8dbf78sdf678dsfc08d0c";
  ENV.ACCESS_KEY = "v8cebfbf744062c00aa85dabcc68c08d0c";
  ENV.PAYMENT_MODE = "sandbox";
}

export default ENV;
