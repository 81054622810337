import React from "react";
import "./App.css";
import NotFound from "./view/desktop/screens/NotFound";
import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";

import {
  AboutUs,
  Appointment,
  Broker,
  ContactUs,
  Fleet,
  GetInTouch,
  Home,
  Insurance,
  Insurer,
  Lease,
  Lubricants,
  MakeClaim,
  Oem,
  ServiceDetail,
  Services,
  Solutions,
  TermsCondition,
  PrivacyPolicy,
  Landing,
  CarService,
  CarWorkshopNearMe,
  CarMechanicNearMe,
  CarGarageNearMe,
  WorkshopOnboard,
  MemberEnroll,
  MemberEnrollNew,
  // CarInfo,
  MerchantBook,
  Mtc,
  Career,
  MerchantInsurance,
  Claim,
  CarWashNearMe,
  ZoomHost,
  RecentNews,
  CarRepairNearMe,
  CarAcRepairNearMe,
  Faq,
  Reviews,
  Offers,
  Profile,
  VehicleAI,
  CarServiceNearMe,
  CouponzGuru,
  AccountDeletion,
  Ev,
  DentPaint,
  Warranty,
  AMC,
} from "./view/desktop/screens";
import PaymentSuccess from "./components/common/paymentManagement";
import ServicePaymentResponse from "./view/desktop/screens/ServicePaymentResponse";
// import Cars24 from "./pages/website/Cars24";

const App = () => {
  return (
    
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/:city" element={<Home />} />
      <Route exact path="/:city/services/:id" element={<Services />} />
      <Route exact path="/:city/services/:id/:vehicle" element={<Services />} />
      <Route exact path="/appointment" element={<Appointment />} />
      <Route exact path="/servicedetail" element={<ServiceDetail />} />
      <Route exact path="/aboutus" element={<AboutUs />} />
      <Route exact path="/contactus" element={<ContactUs />} />
      <Route exact path="/career" element={<Career />} />

      <Route exact path="/car-insurance-claim" element={<Insurance />} />

      <Route exact path="/solutions" element={<Solutions />} />
      <Route exact path="/segments/fleet" element={<Fleet source="fleet" />} />
      <Route exact path="/segments/lease" element={<Lease source="lease" />} />
      <Route exact path="/segments/broker" element={<Broker source="broker" />} />
      <Route exact path="/segments/oem" element={<Oem source="oem" />} />
      <Route exact path="/segments/insurer" element={<Insurer source="insurer" />} />
      <Route exact path="/lubricants" element={<Lubricants />} />
      <Route exact path="/makeclaim" element={<MakeClaim />} />
      <Route exact path="/getintouch" element={<GetInTouch />} />
      <Route exact path="/termsncondition" element={<TermsCondition />} />
      <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
      <Route exact path="/landing" element={<Landing custom="delhi" />} />
      <Route exact path="/:city/car-service" element={<CarService />} />
      <Route exact path="/:city/dent-paint" element={<DentPaint />} />
      <Route exact path="/car-workshop-near-me" element={<CarWorkshopNearMe />} />
      <Route exact path="/car-wash-near-me" element={<CarWashNearMe />} />
      <Route exact path="/car-garage-near-me" element={<CarGarageNearMe />} />
      <Route exact path="/car-mechanic-near-me" element={<CarMechanicNearMe />} /> 
      <Route exact path="/workshoponboard" element={<WorkshopOnboard />} />
      <Route exact path="/totalenroll" element={<MemberEnroll partner="VC TotalEnergies" />} />
      <Route exact path="/zoomenroll" element={<MemberEnrollNew partner="VC ZOOM" />} />
      <Route exact path="/enrollPaymentresponse" element={<PaymentSuccess />} />
      <Route exact path="/servicePaymentresponse" element={<ServicePaymentResponse />} />
      {/* <Route exact path="/merchantservice" element={<CarInfo />} /> */}
      <Route exact path="/merchantservice" element={<Landing />} />
      <Route exact path="/merchantinsurance" element={<MerchantInsurance />} />
      <Route exact path="/claim" element={<Claim />} />
      <Route exact path="/merchantbook" element={<MerchantBook />} />
      <Route exact path="/car-scrapping" element={<Mtc />} />
      <Route exact path="/zoomhost" element={<ZoomHost />} />
      <Route exact path="/media" element={<RecentNews />} />
      <Route exact path="/reviews" element={<Reviews />} />
      <Route exact path="/offers" element={<Offers />} />
      <Route exact path="/profile" element={<Profile />} />
      <Route exact path="/faq" element={<Faq />} />
      <Route exact path="/user-account-deletion" element={<AccountDeletion />} />
      <Route exact path="/vehicle-ai" element={<VehicleAI />} />
      <Route exact path="/car-repair-near-me" element={<CarRepairNearMe />} />
      <Route exact path="/car-ac-repair-near-me" element={<CarAcRepairNearMe />} />
      <Route exact path="/car-service-near-me" element={<CarServiceNearMe />} />
      <Route exact path="/couponzguru" element={<CouponzGuru />} />
      {/* <Route exact path="/cars-24" element={<Cars24 />} /> */}
      <Route exact path="/ev" element={<Ev />} />
      <Route exact path="/amc" element={<AMC />} />
      <Route exact path="/warranty" element={<Warranty />} />

      <Route exact path="car-service-:city" element={<CarService />} />
      <Route path="notfound" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/notfound" replace />} />

    </Routes>
    
  );
};

export default App;
