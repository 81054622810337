import { ExtraApiRequest, postLoginApiRequest, preLoginApiRequest } from "../../server";
import { CREATE_WARRANTY, MEMBER_ENROLL, MEMBER_ENROLL_CODE, ORDER_DATA, SERVICE_BOOKING, SERVICE_QUOTE } from "../types";

export const createServiceBooking = (params, dispatch) => {
  postLoginApiRequest(params, (responseData) => {
    dispatch({ type: SERVICE_BOOKING, payload: responseData.data });
  });
};

export const updateServiceBooking = (params, dispatch) => {
  postLoginApiRequest(params, (responseData) => {
    dispatch({ type: SERVICE_BOOKING, payload: responseData.data });
  });
};

export const createServiceQuote = (params, dispatch) => {
  preLoginApiRequest(params, (responseData) => {
    dispatch({ type: SERVICE_QUOTE, payload: responseData.data });
  });
};
export const createMemberEnrollment = (params, dispatch) => {
  ExtraApiRequest(params, (responseData) => {
    dispatch({ type: MEMBER_ENROLL, payload: responseData.data });
  });
};
export const updateMemberEnrollment = (params, dispatch) => {
  ExtraApiRequest(params, (responseData) => {
    dispatch({ type: MEMBER_ENROLL_CODE, payload: responseData.data });
  });
};
export const createCustomServiceBooking = (params, dispatch) => {
  ExtraApiRequest(params, (responseData) => {
    dispatch({ type: SERVICE_BOOKING, payload: responseData.data });
  });
};

export const createWarrantyData = (params, dispatch) => {
  ExtraApiRequest(params, (responseData) => {
    dispatch({ type: CREATE_WARRANTY, payload: responseData.data });
  });
}

export const getOrderData = (params, dispatch) => {
  ExtraApiRequest(params, (responseData) => {
    console.log("responseData--", responseData)
    dispatch({ type: ORDER_DATA, payload: responseData.data });
  });
}