import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import "./index.scss";
import {
  Faqbg,
  Pattern1,
  Pattern2,
  Appbg,
} from "../../../../constants/ImageConstants";
import Testimonial from "../../../../components/common/Testimonial";

import VcClaimProcess from "./VcClaimProcess";
import InsuranceBannerSection from "./InsuranceBannerSection";
import OurApp from "../../../../components/common/ourApp";
import Header from "../../layout/NavigationBar/Header";
import Footer from "../../layout/NavigationBar/Footer";
import TrustedCompany from "./trustedCompany";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { OrderAction, UserAction } from "../../../../store/action";

import InsuranceFaq from "./InsuranceFaq";
import Dcx from "./Dcx";
import HowVcHelp from "./HowVcHelp";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import BottomTabs from "../../layout/NavigationBar/BottomsTabs";
import { BottomSheet } from "react-spring-bottom-sheet";
import { colors } from "@mui/material";
import VehicleSelector from "../../../../components/common/vehicleSelectorMobile";
import { Trash3 } from "react-bootstrap-icons";
import InnerHeader from "../../layout/NavigationBar/InnerHeader";

const Insurance = (props) => {
  const { cityData, userData } = props;
  const { is_user_logged_in, full_profile } = userData;
  const [bottomSheet, setBottomSheet] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  let profileData = full_profile?.user || full_profile;
  useEffect(() => {
    if (full_profile) {
      setSelectedVehicle(
        profileData?.vehicles?.filter((item) => item?.is_prefered === "1")[0]
      );
    } else if (window.sessionStorage.getItem("vehicleDetails")) {
      let parse = JSON.parse(window.sessionStorage.getItem("vehicleDetails"));
      const obj = {
        brand_name: parse?.brand?.name,
        model_name: parse?.model?.name,
        variant_name: parse?.variant?.variant,
        icon: parse?.model?.icon,
      };
      setSelectedVehicle(obj);
    }
  }, [full_profile]);

  const makeDefaultVehicle = (id) => {
    props?.updateUserVehicle({
      task: "setUserVehiclesPreffered",
      id: id,
    });
  };
  const removeVehicle = (id) => {
    props?.updateUserVehicle({
      task: "removeUserVehicles",
      id: id,
    });
  };

  const addNewCar = (vehicleDetails) => {
    props?.updateUserVehicle({
      task: "addUserVehicles",
      brand_id: vehicleDetails.brand.id,
      model_id: vehicleDetails.model.id,
      variant_id: vehicleDetails.variant.id,
    });
  };
  return (
    <>
      <Helmet>
        <title>Find The Best Car Accidental Insurance Claim Services</title>
        <meta
          name="description"
          content="Can You Search for Book cashless Car insurance Claim service with a hassle-free process and towing service from vehicleCare"
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content="Find The Best Car Accidental Insurance Claim Services"
        />
        <meta
          property="og:description"
          content="Can You Search for Book cashless Car insurance Claim service with a hassle-free process and towing service from vehicleCare "
        />
        <meta
          property="og:url"
          content={`https://vehiclecare.in/car-insurance-claim`}
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="VehicleCare" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />
        <meta
          property="twitter:title"
          content="Find The Best Car Accidental Insurance Claim Services"
        />
        <meta
          property="twitter:description"
          content="Can You Search for Book cashless Car insurance Claim service with a hassle-free process and towing service from vehicleCare "
        />
        <meta
          property="twitter:url"
          content={`https://vehiclecare.in/car-insurance-claim`}
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@vehiclecare1" />
        <meta
          property="twitter:image"
          content="https://vehiclecare.in/vendor/service_offers/vc-prime-home-banner.jpg"
        />

        <link
          rel="canonical"
          href={`https://vehiclecare.in/car-insurance-claim`}
        />
      </Helmet>

      {/* <Header
        setSheet={(boolean) =>
          is_user_logged_in ? setBottomSheet(boolean) : setOpenModal(boolean)
        }
        selected_vehicle={selectedVehicle}
      /> */}
      <InnerHeader title={'Insurance Claim'} />
       <BottomSheet open={bottomSheet} onDismiss={() => setBottomSheet(false)}>
        <h4
          style={{
            color: colors.common.black,
            fontWeight: "bold",
            marginLeft: "15px",
          }}
        >
          My Garage
        </h4>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          {profileData?.vehicles?.map((item) => (
            <div
              style={{
                border: `1px solid ${
                  item?.is_prefered === "1" ? "red" : "lightgrey"
                }`,
                margin: "10px",
                padding: "20px 10px 20px 10px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => makeDefaultVehicle(item?.id)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={item?.icon} width={90} />
                <div style={{ marginLeft: "10px" }}>
                  <a style={{ color: colors.common.black, fontWeight: "bold" }}>
                    {item?.model_name}
                  </a>
                  <br />
                  <a>{item?.variant_name}</a>
                </div>
              </div>
              <Trash3
                size={20}
                color={"red"}
                onClick={() => removeVehicle(item?.id)}
              />
            </div>
          ))}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center" }}
          onClick={() => {
            let vehicleDetails =
              window.sessionStorage.getItem("vehicleDetails");
            // if(!is_user_logged_in && !vehicleDetails){
            setOpenModal(true);
            setBottomSheet(false);
            // }
          }}
        >
          <button
            style={{ width: "93%", borderRadius: "5px" }}
            class="theme-btn btn-style-four btn-sm px-2 py-2 my-2"
          >
            <span class="txt">Add New Car +</span>
          </button>
        </div>
      </BottomSheet>
      {openModal && (
        <VehicleSelector
          navigate={false}
          hideBar={true}
          open={true}
          setModal={(bool) => setOpenModal(bool)}
          navigFun={!is_user_logged_in ? false : addNewCar}
        />
      )}
      <Row>
        <Col>
          {/* Banner Section */}
          <section className="banner-section-three style-two">
            <div className="slide">
              <InsuranceBannerSection />
            </div>
          </section>

          {/* How VehicleCare can help */}
          <section
            className="about-section pb-5"
            style={{ background: "rgb(251, 251, 251)" }}
          >
            <HowVcHelp />
          </section>

          {/* How to file for claim on VehicleCare */}
          <section className="about-section pt-5 pb-5">
            <VcClaimProcess />
          </section>

          {/* Trusted Company & Documents Required: */}
          <section className="about-section pt-0">
            <div
              className="pattern-layer-one"
              style={{ backgroundImage: `url(${Pattern1})` }}
            ></div>
            <div
              className="pattern-layer-two"
              style={{ backgroundImage: `url(${Pattern2})` }}
            ></div>

            <TrustedCompany />
          </section>

          <section
            className="about-section"
            style={{ background: "rgb(251, 251, 251)" }}
          >
            <Dcx />
          </section>

          <div
            className="acordian-section pb-4"
            style={{
              backgroundImage: `url(${Faqbg})`,
              backgroundSize: "cover",
            }}
          >
            <InsuranceFaq />
          </div>

          {/* Testimonial Section  */}
          <div className="d-none d-md-block">
            <Testimonial city_name={cityData.selectedCity} />
          </div>

          {/* Our App */}
          <div
            className="testimonial-section-one bg-size pb-5"
            style={{
              backgroundImage: `url(${Appbg})`,
              backgroundSize: `cover`,
              backgroundColor: `#f5f1ee`,
            }}
          >
            <OurApp />
          </div>
        </Col>
      </Row>
      <Footer />
      <BottomTabs route_name={"claim"} user={is_user_logged_in} />
    </>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.serviceBookingData = state.default.orderReducer;
  return stateObj;
};
const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  createServiceQuote: (params) =>
    OrderAction.createServiceQuote(params, dispatch),
  updateUserVehicle: (params) => UserAction.updateUserVehicle(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Insurance);
