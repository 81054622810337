import React, { useEffect, useState } from "react";
import { banner, HomeBanner2 } from "../../../../constants/ImageConstants";
import { colors } from "@mui/material";
import "./index.css";
import InnerHeader from "../../layout/NavigationBar/InnerHeader";
import {
  ServicePackagesListAction,
  UserAction,
  VehicleAction,
} from "../../../../store/action";
import { connect } from "react-redux";
import {
  CheckCircle,
  CheckCircleFill,
  ClockFill,
  Trash3,
} from "react-bootstrap-icons";
import { BottomSheet } from "react-spring-bottom-sheet";
import { useNavigate, useParams } from "react-router-dom";
import { CART_LIST } from "../../../../store/types";
import { ArrowRight } from "react-bootstrap-icons";
import BottomTabs from "../../layout/NavigationBar/BottomsTabs";
import VehicleSelector from "../../../../components/common/vehicleSelectorMobile";
import { isIOS } from "react-device-detect";
import WhyChoose from "../Services/WhyChoose";
import OilProduct from "../Lubricants/OilProduct";
import Footer from "../../layout/NavigationBar/Footer";

const PeriodicServices = (props) => {
  const [bottomSheet, setBottomSheet] = useState(false);
  const [itemSheet, setItemSheet] = useState(false);
  const [periodicServices, setPeriodicServices] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [disable, setDisable] = useState(false);
  const [bannerPath, setBannerPath] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const urlParam = useParams();
  const { is_user_logged_in } = props?.userData;
  const header_title =
    urlParam?.id === "periodic-car-services"
      ? "Periodic Services"
      : urlParam?.id === "denting-painting"
      ? "Denting & Painting"
      : urlParam?.id === "mechanical-repairs"
      ? "Mechanical Repairs"
      : urlParam?.id === "car-ac-services"
      ? " Car AC Services"
      : urlParam?.id === "wheel-care"
      ? "Wheel Care"
      : urlParam?.id === "car-cleaning"
      ? "Car Cleaning"
      : urlParam?.id === "car-detailing-&-spa"
      ? "Car Detailing & Spa"
      : urlParam?.id === "custom-repairs"
      ? "Custom Repairs"
      : "";
  let cartList = props.cartList.cartList;

  useEffect(() => {
    const discounts = window.sessionStorage.getItem("discounts");
    let ids = [];
    if (urlParam?.id === "periodic-car-services") {
      setBannerPath(
        require("../../../../assets/images/banner/periodic-home-banner.jpg")
      );
      ids = ["1", "2", "3"];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
            duration: "1-2 days",
          });
        }
      });
      setPeriodicServices(arr);
    } else if (urlParam?.id === "denting-painting") {
      setBannerPath(
        require("../../../../assets/images/banner/dent-paint-banner.jpg")
      );
      ids = [
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
        "13",
        "14",
        "15",
        "16",
        "17",
        "18",
        "19",
        "20",
        "21",
      ];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
            duration: item?.id == "21" ? "7 days" : "1-2 days",
          });
        }
      });
      setPeriodicServices(arr);
    } else if (urlParam?.id === "mechanical-repairs") {
      setBannerPath(
        require("../../../../assets/images/banner/custom-repair-solution-banner.jpg")
      );
      ids = [
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
        "32",
        "33",
        "34",
        "35",
        "36",
        "37",
        "38",
        "39",
        "40",
        "41",
        "42",
        "43",
        "44",
        "45",
        "46",
        "47",
        "48",
        "49",
        "50",
        "51",
        "52",
        "53",
        "54",
        "55",
        "56",
        "57",
        "56",
        "57",
        "58",
        "59",
        "60",
        "61",
        "62",
        "63",
        "64",
        "65",
        "66",
        "67",
        "68",
        "69",
        "70",
        "71",
        "72",
        "73",
        "74",
        "75",
        "76",
        "77",
        "78",
        "79",
        "80",
        "81",
        "82",
        "83",
        "84",
        "85",
        "86",
        "87",
        "88",
        "89",
        "90",
        "91",
        "92",
        "93",
        "94",
        "95",
        "96",
        "97",
      ];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
          });
        }
      });
      setPeriodicServices(arr);
    } else if (urlParam?.id === "car-ac-services") {
      setBannerPath(require("../../../../assets/images/banner/ac-banner.jpg"));
      ids = ["98", "99", "100", "101"];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
            duration:
              item?.id == "98"
                ? "30 mins"
                : item?.id == "99"
                ? "1 hour"
                : item?.id == "100"
                ? "12 hours"
                : "1 hour",
          });
        }
      });
      setPeriodicServices(arr);
    } else if (urlParam?.id === "wheel-care") {
      setBannerPath(
        require("../../../../assets/images/banner/wheel-banner.jpg")
      );
      ids = ["102", "103"];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
            duration: "30 mins",
          });
        }
      });
      setPeriodicServices(arr);
    } else if (urlParam?.id === "car-cleaning") {
      setBannerPath(
        require("../../../../assets/images/banner/car-cleaning-banner.jpg")
      );
      ids = ["104", "105"];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
            duration: item?.id == "104" ? "1 hour" : "24 hours",
          });
        }
      });
      setPeriodicServices(arr);
    } else if (urlParam?.id === "car-detailing-&-spa") {
      setBannerPath(
        require("../../../../assets/images/banner/car-cleaning-banner.jpg")
      );
      ids = ["107", "108", "109", "110", "111", "114"];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
            duration: "1-2 Days",
          });
        }
      });
      setPeriodicServices(arr);
    } else if (urlParam?.id === "custom-repairs") {
      setBannerPath(
        require("../../../../assets/images/banner/custom-repair-solution-banner.jpg")
      );
      ids = ["112", "113", "115"];
      const arr = [];
      props?.servicePackageList?.servicePackageList?.map((item) => {
        if (ids?.includes(item?.id)) {
          arr.push({
            ...item,
            discount: JSON.parse(discounts)?.hasOwnProperty(item?.id)
              ? JSON.parse(discounts)[item?.id]
              : false,
            duration: "30 mins",
          });
        }
      });
      setPeriodicServices(arr);
    }
  }, [props?.servicePackageList]);

  useEffect(() => {
    const params = { task: "getCardBrands" };
    props.getBrandNames(params);
  }, [props?.getBrandNames]);

  const vehicles = props?.userData?.full_profile?.user?.vehicles
    ? props?.userData?.full_profile?.user?.vehicles
    : props?.userData?.full_profile?.vehicles;
  const makeDefaultVehicle = (id) => {
    props?.updateUserVehicle({
      task: "setUserVehiclesPreffered",
      id: id,
    });
  };
  const removeVehicle = (id) => {
    props?.updateUserVehicle({
      task: "removeUserVehicles",
      id: id,
    });
  };

  function fetchData() {
    // if (is_user_logged_in) {
    // const [selectedCar] = vehicles?.filter(
    //   (item) => item?.is_prefered === "1"
    // );
    // const params = {
    //   task: "getServiceData",
    //   variant_id: selectedCar?.variant_id,
    // };
    // props?.getServiceData(params);
    // setVehicleDetails(selectedCar);
    // }
    if (window.sessionStorage.getItem("vehicleDetails")) {
      let vehicleDetails = window.sessionStorage.getItem("vehicleDetails");
      if (vehicleDetails) {
        let parse = JSON.parse(window.sessionStorage.getItem("vehicleDetails"));
        const obj = {
          brand_name: parse?.brand?.name,
          model_name: parse?.model?.name,
          variant_name: parse?.variant?.variant,
          icon: parse?.model?.icon,
        };
        setVehicleDetails(obj);
        const params = {
          task: "getServiceData",
          variant_id: parse?.variant?.id,
        };
        props?.getServiceData(params);
      } else {
        setVehicleDetails(null);
      }
    } else {
      setOpenModal(true);
    }
  }

  // useEffect(() => {
  //   const params = {
  //     task: "getServiceData",
  //     variant_id: vehicleDetails?.variant_id,
  //   };
  //   vehicleDetails?.variant_id && props?.getServiceData(params);
  // }, [vehicleDetails])

  // useEffect(() => {
  //   const [selectedCar] = vehicles?.filter(
  //     (item) => item?.is_prefered === "1"
  //   );
  //   setVehicleDetails(selectedCar);
  // }, [props?.userData?.full_profile])

  const addToCartList = (id) => {
    const mainService = [1, 2, 3];
    let existService = cartList.filter(
      (elem) => mainService.indexOf(parseInt(elem)) > -1
    );
    if (mainService.indexOf(parseInt(id)) > -1 && existService.length) {
      removeCart(existService[0]);
    }
    props.updateCart({ type: CART_LIST, payload: [...cartList, id] });
    setItemSheet(false);
  };

  const removeCart = (id) => {
    cartList = cartList.filter((elem) => elem !== id);
    props.updateCart({ type: CART_LIST, payload: cartList });
  };
  const navigate = useNavigate();

  const { modelList } = props?.vehicleData;
  function getVehicle() {
    let vehicleArr = urlParam.vehicle.split("-");
    let brandName = vehicleArr[0].trim();
    if (vehicleArr.length > 3) {
      brandName = `${vehicleArr[0].trim()}-${vehicleArr[1].trim()}`;
    }
    let variantName = vehicleArr[vehicleArr.length - 1].trim();
    let modelName = urlParam.vehicle
      .replace(brandName, "")
      .replace(variantName, "")
      .replace(/-/g, " ")
      .trim();
    const params = { task: "getCardModels", search_text: modelName };
    props.getModelNames(params);
  }
  useEffect(() => {
    let vehicleArr = urlParam.vehicle.split("-");
    let variantName = vehicleArr[vehicleArr.length - 1].trim();
    if (
      modelList?.length !== 0 &&
      variantName?.length > 0 &&
      modelList[0].brand_id &&
      modelList[0].model_id
    ) {
      window.sessionStorage.removeItem("vehicleDetails");
      const brandData = {
        id: modelList[0].brand_id,
        name: modelList[0].brand_name,
        icon: "",
      };
      const modelData = {
        id: modelList[0].model_id,
        name: modelList[0].model_name,
        icon: modelList[0].icon,
        segment_type: modelList[0].segment_type,
        variants: modelList[0].variants,
      };
      const newVariantList = modelList[0].variants.filter(
        (elemn) => elemn.variant.toLowerCase() === variantName?.toLowerCase()
      );
      const vehicleDetails = {
        brand: brandData,
        model: modelData,
        variant: newVariantList[0],
      };
      window.sessionStorage.setItem(
        "vehicleDetails",
        JSON.stringify(vehicleDetails)
      );
      fetchData();
    }
  }, [modelList]);

  useEffect(() => {
    getVehicle();
  }, []);

  return (
    <div>
      <InnerHeader
        title={header_title}
        selected_vehicle={vehicleDetails}
        setSheet={(boolean) => setOpenModal(boolean)}
      />
      {openModal && (
        <VehicleSelector
          navigate={true}
          hideBar={true}
          open={true}
          setModal={(bool) => {
            fetchData();
            setOpenModal(bool);
          }}
        />
      )}
      {!bottomSheet &&
        !itemSheet &&
        cartList?.length > 0 &&
        !disable &&
        !openModal && (
          <div className={`footer-fix my-2 pb-4 mb-5`}>
            <div className=" d-flex">
              <button
                onClick={() => navigate("/checkout")}
                style={{
                  width: "93%",
                  backgroundColor: "red",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
                className="mx-auto py-2 text-white rounded d-flex justify-content-between align-items-center"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                  }}
                >
                  <a className="fw-bold text-white">
                    {" "}
                    {cartList?.length}{" "}
                    {cartList?.length === 1 ? "service" : "services"} added
                  </a>
                  <a className="fw-bold text-white"></a>
                </div>
                <div className="d-flex align-items-center">
                  <a className="fw-bold text-white">View Cart </a>{" "}
                  <ArrowRight size={16} />
                </div>
              </button>
            </div>
          </div>
        )}
      <section
        style={{
          marginTop: "80px",
          marginLeft: "20px",
          marginRight: "20px",
        }}
      >
        <img
          src={bannerPath}
          style={{
            marginRight: "20px",
            borderRadius: "10px",
            width: "100%",
            height: "22vh",
          }}
        />
      </section>
      <section
        style={{
          display: "grid",
          gridTemplateColumns: "48% 48%",
          gap: "15px",
          margin: "15px",
          marginBottom: "25px",
        }}
      >
        {periodicServices.map((item, index) => {
          return (
            <div
              className="position-relative"
              onClick={() => setItemSheet(item)}
            >
              <div className="background-gradient"></div>
              <img
                src={item?.icon?.replace(
                  "https://vehiclecare.in",
                  "https://storage.googleapis.com/vc-wave"
                )}
                style={{ borderRadius: "5px", height: "12vh", width: "100%" }}
              />
              <div
                className="position-absolute justify-content-center align-items-center"
                style={{ bottom: "38px", left: "5px" }}
              >
                <ClockFill color="white" size={10} />
                <a
                  style={{ color: "white", fontSize: "10px" }}
                  className="fw-bold"
                >
                  {" "}
                  {item?.duration}
                </a>
              </div>
              <div
                className="position-absolute"
                style={{ bottom: "25px", left: "5px" }}
              >
                <a
                  style={{ color: "white", fontSize: "10px" }}
                  className="fw-bold"
                >
                  ₹{item?.price}
                </a>
              </div>
              <a
                className="text-ellipsis fw-bold text-dark"
                style={{ fontSize: "12px", lineHeight: "10px" }}
              >
                {item?.name}
              </a>
              {cartList?.includes(item?.id) && (
                <img
                  className="position-absolute"
                  style={{ bottom: "27px", right: "2px" }}
                  src={require("../../../../assets/images/checked.png")}
                  width={18}
                  height={18}
                />
              )}
            </div>
          );
        })}
      </section>
      <BottomSheet open={bottomSheet} onDismiss={() => setBottomSheet(false)}>
        <h4
          style={{
            color: colors.common.black,
            fontWeight: "bold",
            marginLeft: "15px",
          }}
        >
          My Garage
        </h4>
        <div
          style={{
            height: "40vh",
            overflowY: "scroll",
          }}
        >
          {vehicles?.map((item) => (
            <div
              style={{
                border: `1px solid ${
                  item?.is_prefered === "1" ? "red" : "lightgrey"
                }`,
                margin: "10px",
                padding: "20px 10px 20px 10px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              onClick={() => makeDefaultVehicle(item?.id)}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img src={item?.icon} width={90} />
                <div style={{ marginLeft: "10px" }}>
                  <a style={{ color: colors.common.black, fontWeight: "bold" }}>
                    {item?.model_name}
                  </a>
                  <br />
                  <a>{item?.variant_name}</a>
                </div>
              </div>
              <Trash3
                size={20}
                color={"red"}
                onClick={() => removeVehicle(item?.id)}
              />
            </div>
          ))}
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            style={{ width: "93%", borderRadius: "5px" }}
            class="theme-btn btn-style-four btn-sm px-2 py-2 my-2"
          >
            <span class="txt">Add New Car +</span>
          </button>
        </div>
      </BottomSheet>
      <BottomSheet open={itemSheet} onDismiss={() => setItemSheet(false)}>
        <div>
          <section className="px-2">
            <img
              src={itemSheet?.icon?.replace(
                "https://vehiclecare.in",
                "https://storage.googleapis.com/vc-wave"
              )}
              style={{
                borderRadius: "10px",
                width: "100vw",
                height: "25vh",
              }}
            />
          </section>
          <div className="d-flex justify-content-between align-items-center px-2 mt-2 mb-2">
            <h6 className="text-dark fw-bold">{itemSheet?.name}</h6>
            <div className="d-flex justify-content-evenly align-items-center w-25">
              {itemSheet?.duration && <ClockFill color="black" />}
              <a className="text-dark">{itemSheet?.duration}</a>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <a className="text-dark fw-bold px-2">What's Included:</a>
            {(itemSheet?.id == "1" ||
              itemSheet?.id == "2" ||
              itemSheet?.id == "104") && (
              <div
                style={{
                  backgroundColor: "#33B740",
                  borderRadius: "5px",
                  marginRight: "10px",
                  fontSize: "10px",
                  height: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                className="text-white fw-bold px-2"
              >
                <CheckCircleFill
                  size={10}
                  style={{ marginRight: "5px", marginBottom: "1px" }}
                />
                <a>Doorstep Available</a>
              </div>
            )}
          </div>
          <div
            className="mx-2 p-2 text-dark mt-2"
            style={{
              maxHeight: "150px",
              border: "0.5px solid lightgrey",
              overflowY: "scroll",
              borderRadius: "5px",
            }}
          >
            {itemSheet?.custom_points?.map((item) => (
              <div className="mb-1">
                <img
                  src={require("../../../../assets/images/checked.png")}
                  width={15}
                  height={15}
                />{" "}
                <a>{item.name}</a>
              </div>
            ))}
          </div>
          <div className="d-flex justify-content-evenly align-items-center">
            {/* {itemSheet?.discount && (
              <div
                style={{ display: "flex", justifyContent: "center" }}
                onClick={() =>
                  isIOS
                    ? (window.location =
                        "https://apps.apple.com/in/app/vehiclecare/id1634342039")
                    : (window.location =
                        "https://play.google.com/store/apps/details?id=com.app.arvindVehicleCare&hl=en_IN")
                }
              >
                <button
                  className=" btn-sm px-2 my-2 mx-2"
                  style={{
                    backgroundColor: "white",
                    border: "1px solid red",
                    width: "30vw",
                    borderRadius: "5px",
                    position: "relative",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  <div className="animated">
                    <span
                      className="txt"
                      style={{ color: "red", fontWeight: "bold" }}
                    >
                      Save ₹
                      {Math.ceil(
                        (itemSheet?.price * itemSheet?.discount) / 100
                      )}
                    </span>
                  </div>
                </button>
              </div>
            )} */}

            <div
              style={{ display: "flex", justifyContent: "center" }}
              onClick={() =>
                cartList?.includes(itemSheet?.id)
                  ? removeCart(itemSheet?.id)
                  : addToCartList(itemSheet?.id)
              }
            >
              <button
                style={{
                  // width: itemSheet?.discount ? "60vw" : "95vw",
                  width: "95vw",
                  borderRadius: "5px",
                }}
                className="theme-btn btn-style-four btn-sm px-2 py-2 my-2 mx-2"
              >
                <span className="txt">
                  {cartList?.includes(itemSheet?.id)
                    ? "Remove Item"
                    : `Add to cart - ₹${itemSheet?.price}`}
                </span>
              </button>
            </div>
          </div>
        </div>
      </BottomSheet>
      <div>
        <div
          style={{
            height: "15px",
            background: "#f6f6f6",
            marginBottom: "10px",
          }}
        ></div>
        <WhyChoose />
      </div>
      <div className="mb-2 pb-5">
        <div
          style={{
            height: "15px",
            background: "#f6f6f6",
            marginBottom: "30px",
          }}
        ></div>
        <OilProduct />
      </div>
      <Footer />
      {!bottomSheet && !itemSheet && (
        <BottomTabs
          route_name={"home"}
          user={is_user_logged_in}
          disable={(bool) => setDisable(bool)}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  stateObj.servicePackageList = state.default.servicePackagesListReducer;
  stateObj.serviceList = state.default.serviceListReducer;
  stateObj.cartList = state.default.cartListReducer;
  return stateObj;
};

const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
  updateUserVehicle: (params) => UserAction.updateUserVehicle(params, dispatch),
  getServiceData: (params) =>
    ServicePackagesListAction.getServiceData(params, dispatch),
  updateCart: (params) => dispatch(params),
  getBrandNames: (params) => VehicleAction.getBrandNames(params, dispatch),
  getModelNames: (params) => VehicleAction.getModelNames(params, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(PeriodicServices);
