const getNavigateUrl = (url) => {
  const selectedCity = window.sessionStorage.getItem("selectedCity");
  let newPath = url;
  if (selectedCity) {
    const selectedCityName = JSON.parse(selectedCity).name.toLowerCase();
    newPath = `/${selectedCityName.replace(/ /g, "-")}${url}`;
  }
  if (url.indexOf("/custom_service/") > -1) {
    return newPath.replace(/\/+$/, "").replace(/custom_service/g, "services");
  }
  if (url.indexOf("/services/") > -1 && window.sessionStorage.getItem("vehicleDetails")) {
    const vehicleCustData = JSON.parse(window.sessionStorage.getItem("vehicleDetails"));
    const vehiclePath = `${vehicleCustData.brand.name}-${vehicleCustData.model.name}-${vehicleCustData.variant.variant}`.replace(/ /g, "-").toLowerCase();
    newPath += `/${vehiclePath}`;
  }
  return newPath.replace(/\/+$/, "");
};

const getUrlSearchParams = (params) => {
  const returnArray = [];
  params.forEach((e, k) => {
    returnArray.push({ name: k, value: e });
  });
  return returnArray;
};
const toTitleCase = (s) => {
  if (typeof s === "string" && s.length > 0) {
    const words = s.split(" ");
    if (Array.isArray(words) && words.length > 0) {
      if (words.length === 1) {
        const word = words[0];
        const matches = word.charAt(0).match(/\w+/i);
        const lines = word.split("\n");
        if (Array.isArray(lines) && lines.length > 1) {
          return lines
            .map((line) => {
              return toTitleCase(line);
            })
            .join("\n");
        } else if (Array.isArray(matches)) {
          return word
            .split("")
            .map((c, i) => {
              if (i === 0) {
                return c.toUpperCase();
              }
              return c.toLowerCase();
            })
            .join("");
        } else {
          return word.charAt(0).concat(toTitleCase(word.slice(1)));
        }
      } else {
        return words.map((word) => toTitleCase(word)).join(" ");
      }
    }
  }
  return "";
};
export { getNavigateUrl, getUrlSearchParams, toTitleCase };
