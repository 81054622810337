import { ExtraApiRequest } from "../../server";
import { PAYMENT_SESSION, PAYMENT_STATUS } from "../types";

export const createPaymentSession = (params, dispatch) => {
  ExtraApiRequest(params, (responseData) => {
    dispatch({ type: PAYMENT_SESSION, payload: responseData.data });
    console.log("responseData.data",responseData.data)
  });
};
export const getPaymentStatus = (params, dispatch) => {
  ExtraApiRequest(params, (responseData) => {
    dispatch({ type: PAYMENT_STATUS, payload: responseData.data });
  });
};
