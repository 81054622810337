import React from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import Card from "react-bootstrap/Card";

const OilProduct = () => {
  return (
    <Row>
      <Col>
        <div className="auto-container">
          {/* <div className="sec-title centered">
            <h2> Service In 4 Easy Steps </h2>
          </div> */}

          <div className="d-flex" style={{overflowX: 'scroll', scrollbarWidth: 'thin'}}>
                <Col className="mb-1">
                  <Card style={{width: '75vw', marginRight: '20px', marginRight: '20px'}}>
                    <Card.Header className="text-center bg-light card-header px-1">
                      <b className="fs-6 text-center text-dark font-weight-bold mb-0">
                        TotalEnergies Wash Cockpit Cleaner 500 ML{" "}
                      </b>
                    </Card.Header>

                    <Card.Body className="text-center py-1">
                      <img
                        className="mx-auto w-50"
                        src="https://m.media-amazon.com/images/I/71SpTmm7IcL._SY879_.jpg"
                        alt="TotalEnergies Wash Cockpit Cleaner 500 ML"
                      />
                    </Card.Body>

                    <a
                      href="https://www.amazon.in/TotalEnergies-Wash-Cockpit-Cleaner-500/dp/B0BSDJHYH7?&linkCode=sl1&tag=vehiclecare02-21&linkId=3e4c7124df3f2d594c9ed3fe045bf20f&language=en_IN&ref_=as_li_ss_tl"
                      className="theme-btn btn-style-five btn-sm py-2 btn-block text-center"
                      target="_blank"
                    >
                      <span className="txt">Buy Now</span>
                    </a>
                  </Card>
                </Col>

                <Col className="mb-1">
                  <Card style={{width: '75vw', marginRight: '20px'}}>
                    <Card.Header className="text-center bg-light card-header px-1">
                      <b className="fs-6 text-center text-dark font-weight-bold mb-0">
                        TotalEnergies AIRFRESHNER Spray Lavender 65ML{" "}
                      </b>
                    </Card.Header>

                    <Card.Body className="text-center py-1">
                      <img
                        className="mx-auto w-50"
                        src="https://m.media-amazon.com/images/I/71c-ejTUARL._SY879_.jpg"
                        alt="TotalEnergies AIRFRESHNER Spray Lavender 65ML"
                      />
                    </Card.Body>

                    <a
                      href="https://www.amazon.in/TotalEnergies-AIRFRESHNER-Spray-Lavender-65ML/dp/B0C5RQ1RYH?&linkCode=sl1&tag=vehiclecare02-21&linkId=e6b1aca39d31965cbc2a89f325ad1773&language=en_IN&ref_=as_li_ss_tl"
                      className="theme-btn btn-style-five btn-sm py-2 btn-block text-center"
                      target="_blank"
                    >
                      <span className="txt">Buy Now</span>
                    </a>
                  </Card>
                </Col>

                <Col className="mb-1">
                  <Card style={{width: '75vw', marginRight: '20px'}}>
                    <Card.Header className="text-center bg-light card-header px-1">
                      <b className="fs-6 text-center text-dark font-weight-bold mb-0">
                        TotalEnergies Wash Airfreshner for cars | Vanilla{" "}
                      </b>
                    </Card.Header>

                    <Card.Body className="text-center py-1">
                      <img
                        className="mx-auto w-50"
                        src="https://m.media-amazon.com/images/I/81WQJ7DAkhL._SY879_.jpg"
                        alt="TotalEnergies Wash Airfreshner for cars | Vanilla"
                      />
                    </Card.Body>

                    <a
                      href="https://www.amazon.in/TotalEnergies-1113587-AIRFRESHNER-CARD-VANILLA/dp/B0BSDNDD3C?&linkCode=sl1&tag=vehiclecare02-21&linkId=1a96cac27fff3830e8bf51b6e4bb46b1&language=en_IN&ref_=as_li_ss_tl"
                      className="theme-btn btn-style-five btn-sm py-2 btn-block text-center"
                      target="_blank"
                    >
                      <span className="txt">Buy now</span>
                    </a>
                  </Card>
                </Col>

                <Col className="mb-1">
                  <Card style={{width: '75vw', marginRight: '20px'}}>
                    <Card.Header className="text-center bg-light card-header px-1">
                      <b className="fs-6 text-center text-dark font-weight-bold mb-0">
                        TotalEnergies Wash Glass Cleaner 500ML{" "}
                      </b>
                    </Card.Header>

                    <Card.Body className="text-center py-1">
                      <img
                        className="mx-auto w-50"
                        src="https://m.media-amazon.com/images/I/71qsBvUJETL._SY879_.jpg"
                        alt="TotalEnergies Wash Glass Cleaner 500ML"
                      />
                    </Card.Body>

                    <a
                      href="https://www.amazon.in/TotalEnergies-Wash-Glass-Cleaner-500ML/dp/B0BSDP1SCX?&linkCode=sl1&tag=vehiclecare02-21&linkId=86d84ae62bf7ca41df466c4df166221f&language=en_IN&ref_=as_li_ss_tl"
                      className="theme-btn btn-style-five btn-sm py-2 btn-block text-center"
                      target="_blank"
                    >
                      <span className="txt">Buy now</span>
                    </a>
                  </Card>
                </Col>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default OilProduct;
