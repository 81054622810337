import React from "react";
import { connect } from "react-redux";
import style from "styled-components";
import LoaderUrl from "../../assets/images/loader.gif";

export const Loader = style.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url(${LoaderUrl}) 50% 50% no-repeat rgba(255,255,255,0.459);
    background-size: 20rem;
`;
const PageLoader = (props) => {
  const showLoader = props.isLoaderShow;
  return (
    <div className={`${showLoader === false ? "isHidden" : ""} mainPageLoader`}>
      <Loader />
    </div>
  );
};
const mapStateToProps = (state) => state.default.loaderReducer;
export default connect(mapStateToProps)(PageLoader);
