import axios from "axios";
import ENV from "./environment";
import Cookies from "js-cookie";
import { customDecrypt } from "../utils/authHelper";

const client = axios.create({
  baseURL: ENV.BASE_URL,
});

const ExtraApiRequest = (params = {}, callback) => {
  params.access_key = ENV.ACCESS_KEY;
  params.state = ENV.POST_LOGIN;
  client.post("", params).then((response) => {
    if (response.data) callback(response.data);
  });
};
const preLoginApiRequest = (params = {}, callback) => {
  params.access_key = ENV.ACCESS_KEY;
  params.state = ENV.PRE_LOGIN;
  client.post("", params).then((response) => {
    callback(response.data);
  });
};
const loginApiRequest = (params = {}, callback) => {
  params.source = ENV.DEVICE;
  params.access_key = ENV.ACCESS_KEY;
  params.state = ENV.LOGIN;
  client.post("", params).then((response) => {
    if (response.data) callback(response.data);
  });
};
const postLoginApiRequest = (params = {}, callback) => {
  params.source = ENV.DEVICE;
  params.access_key = ENV.ACCESS_KEY;
  params.state = ENV.POST_LOGIN;
  params.user_id = Cookies.get("user_id");
  params.token = customDecrypt(window.localStorage.getItem("user_token"));
  client.post("", params).then((response) => {
    if (response.data.status === "0" || response.data.response_code === "300") {
      alert("You are logged out. Kindly login again.");
      window.localStorage.removeItem("userProfile");
      window.localStorage.removeItem("fullProfile");
      window.localStorage.removeItem("user_token");
      Cookies.remove("user_id");
      window.location.reload();
    }
    if (response.data) callback(response.data);
  });
};
const blogRequest = (callback) => {
  axios.get("https://vehiclecare.in/blaze/wp-json/wp/v2/posts?_embed").then((response) => {
    callback(response.data);
  });
};
const insurancePreLoginApiRequest = (params = {}, callback) => {
  params.insurance_partner_access_key = ENV.INSURANCE_PARTNER_ACCESS_KEY;
  params.state = ENV.VC_PARTNER_LOGIN;
  params.task = "getPartnerAccessToken";
  client.post("", params).then((response) => {
    callback(response.data);
  });
};
const insurancePostLoginApiRequest = (params, callback) => {
  params.insurance_partner_access_key = ENV.INSURANCE_PARTNER_ACCESS_KEY;
  params.state = ENV.VC_PARTNER_POST_LOGIN;
  insurancePreLoginApiRequest({}, function (tokenResponse) {
    params.token = tokenResponse.data.access_token;
    client.post("", params).then((response) => {
      callback(response.data);
    });
  });
};
const insuranceCreateLeadRequest = (params, callback) => {
  params.insurance_partner_access_key = ENV.INSURANCE_PARTNER_ACCESS_KEY;
  params.state = ENV.VC_PARTNER_POST_LOGIN;
  insurancePreLoginApiRequest({}, function (tokenResponse) {
    params.token = tokenResponse.data.access_token;
    if (!params.partner_id) {
      params.partner_id = ENV.PARTNER_ID;
    }
    if (!params.partner_name) {
      params.partner_name = ENV.PARTNER_NAME;
    }
    client.post("", params).then((response) => {
      callback(response.data);
    });
  });
};
const insuranceMediaUploadRequest = (params, callback) => {
  insurancePreLoginApiRequest({}, function (tokenResponse) {
    const formData = new FormData();
    formData.append("state", ENV.VC_PARTNER_POST_LOGIN);
    formData.append("insurance_partner_access_key", ENV.INSURANCE_PARTNER_ACCESS_KEY);
    formData.append("partner_id", ENV.PARTNER_ID);
    formData.append("token", tokenResponse.data.access_token);

    formData.append("task", params.task);
    formData.append("proposal_id", params.proposal_id);
    formData.append("type", params.type);
    for (let index = 0; index < params.file.length; index++) {
      const element = params.file[index];
      formData.append(`file${index}`, element);
    }
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    client.post("", formData, config).then((response) => {
      callback(response.data);
    });
  });
};

export {
  ExtraApiRequest,
  preLoginApiRequest,
  loginApiRequest,
  postLoginApiRequest,
  blogRequest,
  insurancePreLoginApiRequest,
  insurancePostLoginApiRequest,
  insuranceCreateLeadRequest,
  insuranceMediaUploadRequest,
};
