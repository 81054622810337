import React, { useEffect, useState } from "react";
import InnerHeader from "../NavigationBar/InnerHeader";
import { ChevronRight } from "react-bootstrap-icons";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const Search = (props) => {
  const {
    cityData: { selectedCity },
    userData: { full_profile },
  } = props;
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [search, setSearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const initialData = [
    {
      title: "Car Service",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Advance Car Service",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/comprehensive-service.png"),
    },
    {
      title: "Insurance Claim",
      path: `/car-insurance-claim`,
      //   image: require("../../assets/images/insurance-claim.png"),
    },
    {
      title: "Cleaning/Coating",
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
      //   image: require("../../assets/images/car-wash.png"),
    },
    {
      title: "Wheel Care",
      path: `/${selectedCity?.toLowerCase()}/services/wheel-care/`,
      //   image: require("../../assets/images/car-wheel.png"),
    },
    {
      title: "Denting & Painting",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
    },
    {
      title: "Custom Repair",
      path: `/${selectedCity?.toLowerCase()}/services/custom-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
    },
    {
      title: "Car Health Report",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Car Scan Report",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Engine Oil Replacement",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Oil Filter Replacement",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Air Filter Replacement",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Wiper Fluid Replacement",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "A/C Filter Cleaning",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Coolant Top Up",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Brake Fluid Top Up",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Battery Water Top Up",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Rear Brake Shoes Cleaning",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Heater/Spark plugs checking",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Fuel Filter Checking",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Car Scanning",
      path: `/${selectedCity?.toLowerCase()}/services/custom-repairs/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Car Inspection",
      path: `/${selectedCity?.toLowerCase()}/services/custom-repairs/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Interior Vaccuming",
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Dashboard & Tyre Polish",
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Car Wash",
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "Car Wash",
      },
    },
    {
      title: "Front Brake Pads Cleaning",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/periodic_service.png"),
    },
    {
      title: "Gear Oil Topup",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require("../../assets/images/comprehensive-service.png"),
    },
    {
      title: "Car Interior Dry Cleaning",
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "Car Interior Dry Cleaning",
      },
    },
    {
      title: "Complete Car Detailing",
      path: `/${selectedCity?.toLowerCase()}/services/car-detailing-&-spa/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "Complete Car Detailing",
      },
    },
    {
      title: "Ceramic Coating",
      path: `/${selectedCity?.toLowerCase()}/services/car-detailing-&-spa/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "Ceramic Coating",
      },
    },
    {
      title: "PPF - Paint Protection Film",
      path: `/${selectedCity?.toLowerCase()}/services/car-detailing-&-spa/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "PPF - Paint Protection Film",
      },
    },
    {
      title: "Rubbing Polishing",
      path: `/${selectedCity?.toLowerCase()}/services/car-detailing-&-spa/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "Rubbing Polishing",
      },
    },
    {
      title: "Teflon Coating",
      path: `/${selectedCity?.toLowerCase()}/services/car-detailing-&-spa/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "Teflon Coating",
      },
    },
    {
      title: "Car Scanning",
      path: `/${selectedCity?.toLowerCase()}/services/custom-repairs/`,
      //   image: require("../../assets/images/car-wash.png"),
      params: {
        type: "Car Scanning",
      },
    },
    {
      title: "Wheel Alignment",
      path: `/${selectedCity?.toLowerCase()}/services/wheel-care/`,
      //   image: require("../../assets/images/car-wheel.png"),
      params: {
        type: "Wheel Alignment",
      },
    },
    {
      title: "Wheel Balancing",
      path: `/${selectedCity?.toLowerCase()}/services/wheel-care/`,
      //   image: require("../../assets/images/car-wheel.png"),
      params: {
        type: "Wheel Balancing",
      },
    },
    {
      title: "AC Gas CheckUp",
      path: `/${selectedCity?.toLowerCase()}/services/car-ac-services/`,
      //   image: require("../../assets/images/car-ac.png"),
      params: {
        type: "AC Gas CheckUp",
      },
    },
    {
      title: "AC Service",
      path: `/${selectedCity?.toLowerCase()}/services/car-ac-services/`,
      //   image: require("../../assets/images/car-ac.png"),
      params: {
        type: "AC Service",
      },
    },
    {
      title: "AC Compressor",
      path: `/${selectedCity?.toLowerCase()}/services/car-ac-services/`,
      //   image: require("../../assets/images/car-ac.png"),
      params: {
        type: "AC Compressor",
      },
    },
    {
      title: "AC Gas",
      path: `/${selectedCity?.toLowerCase()}/services/car-ac-services/`,
      //   image: require("../../assets/images/car-ac.png"),
      params: {
        type: "AC Gas",
      },
    },
    {
      title: "Bonnet",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Bonnet",
      },
    },
    {
      title: "Boot",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Boot",
      },
    },
    {
      title: "Left Fender",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Left Fender",
      },
    },
    {
      title: "Right Fender",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Right Fender",
      },
    },
    {
      title: "Front Bumper",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Front Bumper",
      },
    },
    {
      title: "Left Front Door",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Left Front Door",
      },
    },
    {
      title: "Right Front Door",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Right Front Door",
      },
    },
    {
      title: "Left Quarter Panel",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Left Quarter Panel",
      },
    },
    {
      title: "Right Quarter Panel",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Right Quarter Panel",
      },
    },
    {
      title: "Rear Bumper",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Rear Bumper",
      },
    },
    {
      title: "Left Rear Door",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Left Rear Door",
      },
    },
    {
      title: "Right Rear Door",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Right Rear Door",
      },
    },
    {
      title: "Roof",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Roof",
      },
    },
    {
      title: "Left Running Board",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Left Running Board",
      },
    },
    {
      title: "Right Running Board",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Right Running Board",
      },
    },
    {
      title: "Full Body",
      path: `/${selectedCity?.toLowerCase()}/services/denting-painting/`,
      //   image: require("../../assets/images/car-paint.png"),
      params: {
        type: "Full Body",
      },
    },
    {
      title: "Brake Shoe",
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
      params: {
        type: "Brake Shoe",
      },
    },
    {
      title: "Brake Hoses",
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
      params: {
        type: "Brake Hoses",
      },
    },
    {
      title: "Brake Pads",
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
      params: {
        type: "Brake Pads",
      },
    },
    {
      title: "Clutch Assembly",
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
      params: {
        type: "Clutch Assembly",
      },
    },
    {
      title: "Flywheel",
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
      params: {
        type: "Flywheel",
      },
    },
    {
      title: "Fuel Tank",
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
      params: {
        type: "Fuel Tank",
      },
    },
    {
      title: "Wiper Motor",
      path: `/${selectedCity?.toLowerCase()}/services/mechanical-repairs/`,
      //   image: require("../../assets/images/MechRepair.png"),
      params: {
        type: "Wiper Motor",
      },
    },
  ];
  const [trendingData, setTrendingData] = useState([
    {
      title: "Car Wash",
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
      //   image: require(""),
      params: {
        type: "Car Wash",
      },
    },
    {
      title: "Car Service",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require(""),
    },
    {
      title: "Advance Car Service",
      path: `/${selectedCity?.toLowerCase()}/services/periodic-car-services/`,
      //   image: require(""),
    },
    {
      title: "Insurance Claim",
      path: `/car-insurance-claim`,
      //   image: require(""),
    },
    {
      title: "Cleaning/Coating",
      path: `/${selectedCity?.toLowerCase()}/services/car-cleaning/`,
      //   image: require(""),
    },
  ]);
  const navigate = useNavigate();
  useEffect(() => {
    if (full_profile) {
      setSelectedVehicle(
        full_profile?.user?.vehicles?.filter(
          (item) => item?.is_prefered === "1"
        )[0]
      );
    } else if (window.sessionStorage.getItem("vehicleDetails")) {
      let parse = JSON.parse(window.sessionStorage.getItem("vehicleDetails"));
      const obj = {
        brand_name: parse?.brand?.name,
        model_name: parse?.model?.name,
        variant_name: parse?.variant?.variant,
        icon: parse?.model?.icon,
      };
      setSelectedVehicle(obj);
    }
  }, [full_profile]);

  useEffect(() => {
    setSearchData(initialData.filter((item) => item?.title?.includes(search)));
  }, [search]);
  return (
    <div>
      <InnerHeader title={"Search"} />
      <section>
        <div
          style={{
            border: "1px solid lightgrey",
            width: "93%",
            height: "48px",
            marginTop: "90px",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: "10px",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 0px 3px 1px lightgrey",
          }}
        >
          <img
            src={require("../../../../assets/images/home/Searchicon.png")}
            style={{ marginLeft: "10px" }}
            width={25}
            height={25}
          />
          <input
            placeholder="Search car services..."
            style={{ width: "100%", paddingLeft: "10px" }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            autoFocus={true}
          />
        </div>
      </section>
      <section className="mx-3 mt-3">
        <div>
          {search.length === 0 && (
            <a className="text-dark fw-bold">Trending Services</a>
          )}
          <div className="mt-2">
            {search.length === 0 &&
              trendingData.map((item) => {
                return (
                  <div
                    onClick={() => {
                      if (item.title === "Insurance Claim") {
                        navigate(item.path);
                      } else {
                        navigate(
                          `${
                            item.path +
                            selectedVehicle?.brand_name?.toLowerCase() +
                            "-" +
                            selectedVehicle?.model_name?.toLowerCase() +
                            "-" +
                            selectedVehicle?.variant_name?.toLowerCase()
                          }`
                        );
                      }
                    }}
                    className="py-1 px-2 d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      marginBottom: "15px",
                      boxShadow: "0px 0px 3px 1px lightgrey",
                    }}
                  >
                    <a className="text-dark fw-bold">{item.title}</a>
                    <ChevronRight color="black" />
                  </div>
                );
              })}

            {search.length !== 0 &&
              searchData.map((item) => {
                return (
                  <div
                    onClick={() => {
                      if (item.title === "Insurance Claim") {
                        navigate(item.path);
                      } else {
                        navigate(
                          `${
                            item.path +
                            selectedVehicle?.brand_name?.toLowerCase() +
                            "-" +
                            selectedVehicle?.model_name?.toLowerCase() +
                            "-" +
                            selectedVehicle?.variant_name?.toLowerCase()
                          }`
                        );
                      }
                    }}
                    className="py-1 px-2 d-flex justify-content-between align-items-center"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      marginBottom: "15px",
                      boxShadow: "0px 0px 3px 1px lightgrey",
                    }}
                  >
                    <a className="text-dark fw-bold">{item.title}</a>
                    <ChevronRight color="black" />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </div>
  );
};
const mapStateToProps = (state) => {
  const stateObj = {};
  stateObj.vehicleData = state.default.vehicleReducer;
  stateObj.cityData = state.default.cityListReducer;
  stateObj.userData = state.default.userReducer;
  return stateObj;
};

const mapDispatchToProps = (dispatch) => ({
  customDispatch: (params) => dispatch(params),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);
